/**
Theme Weight: hairline
FontWeight: 100
**/

/* Webfont: Lato-Hairline */
@font-face {
  font-family: "Dinos CV";
  src: url("./Lato-Hairline.eot") /* IE9 Compat Modes */;
  src: url("./Lato-Hairline.eot?#iefix") format("embedded-opentype")
      /* IE6-IE8 */,
    url("./Lato-Hairline.woff2") format("woff2") /* Modern Browsers */,
    url("./Lato-Hairline.woff") format("woff") /* Modern Browsers */,
    url("./Lato-Hairline.ttf") format("truetype");
  font-style: normal;
  font-weight: 100;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-HairlineItalic */
@font-face {
  font-family: "Dinos CV";
  src: url("./Lato-HairlineItalic.eot") /* IE9 Compat Modes */;
  src: url("./Lato-HairlineItalic.eot?#iefix") format("embedded-opentype")
      /* IE6-IE8 */,
    url("./Lato-HairlineItalic.woff2") format("woff2") /* Modern Browsers */,
    url("./Lato-HairlineItalic.woff") format("woff") /* Modern Browsers */,
    url("./Lato-HairlineItalic.ttf") format("truetype");
  font-style: italic;
  font-weight: 100;
  text-rendering: optimizeLegibility;
}

/**
Theme Weight: thin
FontWeight: 200
**/

/* Webfont: Lato-Thin */
@font-face {
  font-family: "Dinos CV";
  src: url("./Lato-Thin.eot") /* IE9 Compat Modes */;
  src: url("./Lato-Thin.eot?#iefix") format("embedded-opentype") /* IE6-IE8 */,
    url("./Lato-Thin.woff2") format("woff2") /* Modern Browsers */,
    url("./Lato-Thin.woff") format("woff") /* Modern Browsers */,
    url("./Lato-Thin.ttf") format("truetype");
  font-style: normal;
  font-weight: 200;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-ThinItalic */
@font-face {
  font-family: "Dinos CV";
  src: url("./Lato-ThinItalic.eot") /* IE9 Compat Modes */;
  src: url("./Lato-ThinItalic.eot?#iefix") format("embedded-opentype")
      /* IE6-IE8 */,
    url("./Lato-ThinItalic.woff2") format("woff2") /* Modern Browsers */,
    url("./Lato-ThinItalic.woff") format("woff") /* Modern Browsers */,
    url("./Lato-ThinItalic.ttf") format("truetype");
  font-style: italic;
  font-weight: 200;
  text-rendering: optimizeLegibility;
}

/**
Theme Weight: light
FontWeight: 300
**/

/* Webfont: Lato-Light */
@font-face {
  font-family: "Dinos CV";
  src: url("./Lato-Light.eot") /* IE9 Compat Modes */;
  src: url("./Lato-Light.eot?#iefix") format("embedded-opentype") /* IE6-IE8 */,
    url("./Lato-Light.woff2") format("woff2") /* Modern Browsers */,
    url("./Lato-Light.woff") format("woff") /* Modern Browsers */,
    url("./Lato-Light.ttf") format("truetype");
  font-style: normal;
  font-weight: 300;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-LightItalic */
@font-face {
  font-family: "Dinos CV";
  src: url("./Lato-LightItalic.eot") /* IE9 Compat Modes */;
  src: url("./Lato-LightItalic.eot?#iefix") format("embedded-opentype")
      /* IE6-IE8 */,
    url("./Lato-LightItalic.woff2") format("woff2") /* Modern Browsers */,
    url("./Lato-LightItalic.woff") format("woff") /* Modern Browsers */,
    url("./Lato-LightItalic.ttf") format("truetype");
  font-style: italic;
  font-weight: 300;
  text-rendering: optimizeLegibility;
}

/**
Theme Weight: normal
FontWeight: 400
**/

/* Webfont: Lato-Regular */
@font-face {
  font-family: "Dinos CV";
  src: url("./Lato-Regular.eot") /* IE9 Compat Modes */;
  src: url("./Lato-Regular.eot?#iefix") format("embedded-opentype")
      /* IE6-IE8 */,
    url("./Lato-Regular.woff2") format("woff2") /* Modern Browsers */,
    url("./Lato-Regular.woff") format("woff") /* Modern Browsers */,
    url("./Lato-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Regular */
@font-face {
  font-family: "Dinos CV";
  src: url("./Lato-Regular.eot") /* IE9 Compat Modes */;
  src: url("./Lato-Regular.eot?#iefix") format("embedded-opentype")
      /* IE6-IE8 */,
    url("./Lato-Regular.woff2") format("woff2") /* Modern Browsers */,
    url("./Lato-Regular.woff") format("woff") /* Modern Browsers */,
    url("./Lato-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: 400;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Italic */
@font-face {
  font-family: "Dinos CV";
  src: url("./Lato-Italic.eot") /* IE9 Compat Modes */;
  src: url("./Lato-Italic.eot?#iefix") format("embedded-opentype") /* IE6-IE8 */,
    url("./Lato-Italic.woff2") format("woff2") /* Modern Browsers */,
    url("./Lato-Italic.woff") format("woff") /* Modern Browsers */,
    url("./Lato-Italic.ttf") format("truetype");
  font-style: italic;
  font-weight: 400;
  text-rendering: optimizeLegibility;
}

/**
Theme Weight: medium
FontWeight: 500
**/

/* Webfont: Lato-Medium */
@font-face {
  font-family: "Dinos CV";
  src: url("./Lato-Medium.eot") /* IE9 Compat Modes */;
  src: url("./Lato-Medium.eot?#iefix") format("embedded-opentype") /* IE6-IE8 */,
    url("./Lato-Medium.woff2") format("woff2") /* Modern Browsers */,
    url("./Lato-Medium.woff") format("woff") /* Modern Browsers */,
    url("./Lato-Medium.ttf") format("truetype");
  font-style: normal;
  font-weight: 500;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-MediumItalic */
@font-face {
  font-family: "Dinos CV";
  src: url("./Lato-MediumItalic.eot") /* IE9 Compat Modes */;
  src: url("./Lato-MediumItalic.eot?#iefix") format("embedded-opentype")
      /* IE6-IE8 */,
    url("./Lato-MediumItalic.woff2") format("woff2") /* Modern Browsers */,
    url("./Lato-MediumItalic.woff") format("woff") /* Modern Browsers */,
    url("./Lato-MediumItalic.ttf") format("truetype");
  font-style: italic;
  font-weight: 500;
  text-rendering: optimizeLegibility;
}

/**
Theme Weight: semibold
FontWeight: 600
**/

/* Webfont: Lato-Semibold */
@font-face {
  font-family: "Dinos CV";
  src: url("./Lato-Semibold.eot") /* IE9 Compat Modes */;
  src: url("./Lato-Semibold.eot?#iefix") format("embedded-opentype")
      /* IE6-IE8 */,
    url("./Lato-Semibold.woff2") format("woff2") /* Modern Browsers */,
    url("./Lato-Semibold.woff") format("woff") /* Modern Browsers */,
    url("./Lato-Semibold.ttf") format("truetype");
  font-style: normal;
  font-weight: 600;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-SemiboldItalic */
@font-face {
  font-family: "Dinos CV";
  src: url("./Lato-SemiboldItalic.eot") /* IE9 Compat Modes */;
  src: url("./Lato-SemiboldItalic.eot?#iefix") format("embedded-opentype")
      /* IE6-IE8 */,
    url("./Lato-SemiboldItalic.woff2") format("woff2") /* Modern Browsers */,
    url("./Lato-SemiboldItalic.woff") format("woff") /* Modern Browsers */,
    url("./Lato-SemiboldItalic.ttf") format("truetype");
  font-style: italic;
  font-weight: 600;
  text-rendering: optimizeLegibility;
}

/**
Theme Weight: bold
FontWeight: 700
**/

/* Webfont: Lato-Bold */
@font-face {
  font-family: "Dinos CV";
  src: url("./Lato-Bold.eot") /* IE9 Compat Modes */;
  src: url("./Lato-Bold.eot?#iefix") format("embedded-opentype") /* IE6-IE8 */,
    url("./Lato-Bold.woff2") format("woff2") /* Modern Browsers */,
    url("./Lato-Bold.woff") format("woff") /* Modern Browsers */,
    url("./Lato-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: 700;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-BoldItalic */
@font-face {
  font-family: "Dinos CV";
  src: url("./Lato-BoldItalic.eot") /* IE9 Compat Modes */;
  src: url("./Lato-BoldItalic.eot?#iefix") format("embedded-opentype")
      /* IE6-IE8 */,
    url("./Lato-BoldItalic.woff2") format("woff2") /* Modern Browsers */,
    url("./Lato-BoldItalic.woff") format("woff") /* Modern Browsers */,
    url("./Lato-BoldItalic.ttf") format("truetype");
  font-style: italic;
  font-weight: 700;
  text-rendering: optimizeLegibility;
}

/**
Theme Weight: extrabold
FontWeight: 800
**/

/* Webfont: Lato-Heavy */
@font-face {
  font-family: "Dinos CV";
  src: url("./Lato-Heavy.eot") /* IE9 Compat Modes */;
  src: url("./Lato-Heavy.eot?#iefix") format("embedded-opentype") /* IE6-IE8 */,
    url("./Lato-Heavy.woff2") format("woff2") /* Modern Browsers */,
    url("./Lato-Heavy.woff") format("woff") /* Modern Browsers */,
    url("./Lato-Heavy.ttf") format("truetype");
  font-style: normal;
  font-weight: 800;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-HeavyItalic */
@font-face {
  font-family: "Dinos CV";
  src: url("./Lato-HeavyItalic.eot") /* IE9 Compat Modes */;
  src: url("./Lato-HeavyItalic.eot?#iefix") format("embedded-opentype")
      /* IE6-IE8 */,
    url("./Lato-HeavyItalic.woff2") format("woff2") /* Modern Browsers */,
    url("./Lato-HeavyItalic.woff") format("woff") /* Modern Browsers */,
    url("./Lato-HeavyItalic.ttf") format("truetype");
  font-style: italic;
  font-weight: 800;
  text-rendering: optimizeLegibility;
}

/**
Theme Weight: black
FontWeight: 900
**/

/* Webfont: Lato-Black */
@font-face {
  font-family: "Dinos CV";
  src: url("./Lato-Black.eot") /* IE9 Compat Modes */;
  src: url("./Lato-Black.eot?#iefix") format("embedded-opentype") /* IE6-IE8 */,
    url("./Lato-Black.woff2") format("woff2") /* Modern Browsers */,
    url("./Lato-Black.woff") format("woff") /* Modern Browsers */,
    url("./Lato-Black.ttf") format("truetype");
  font-style: normal;
  font-weight: 900;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-BlackItalic */
@font-face {
  font-family: "Dinos CV";
  src: url("./Lato-BlackItalic.eot") /* IE9 Compat Modes */;
  src: url("./Lato-BlackItalic.eot?#iefix") format("embedded-opentype")
      /* IE6-IE8 */,
    url("./Lato-BlackItalic.woff2") format("woff2") /* Modern Browsers */,
    url("./Lato-BlackItalic.woff") format("woff") /* Modern Browsers */,
    url("./Lato-BlackItalic.ttf") format("truetype");
  font-style: italic;
  font-weight: 900;
  text-rendering: optimizeLegibility;
}
